@use "../../styles/mixins" as *;
@include layer(ui) {
    .markdown-wrapper {
        .markdown {
            &--list {
                li {
                    color: var(--s-color-text-primary);
                    margin-top: var(--g-paragraph-spacing-list);

                    &:first-child {
                        margin-top: 0;
                    }
                }
            }

            &--link {
                &:hover,
                &:active {
                    color: var(--s-color-brand-primary-standard);
                }

                &:focus-visible {
                    color: var(--s-color-brand-primary-standard);
                    outline: currentColor solid var(--g-border-width-sm);
                    border-radius: var(--c-markdown-link-border-radius-focus);
                }
            }

            &--blockquote {
                margin: 0;

                p {
                    line-height: var(--g-line-height-600);
                    font-size: var(--g-font-size-600);
                    font-weight: var(--c-markdown-quote-font-weight);
                    color: var(--c-markdown-quote-color-fallback);
                    @include for-tablet-up {
                        font-size: var(--g-font-size-800);
                        line-height: var(--g-line-height-800);
                    }
                }
            }

            &--image {
                display: block;
                max-width: 100%;
                height: auto;
            }

            &--heading {
                color: var(--s-color-text-primary);
            }

            &--body {
                color: var(--s-color-text-primary);
            }

            $elements-xl: p, ul, ol, blockquote;
            $elements-sm: h1, h2, h3, h4, h5, h6;
            $elements-md: p, ol, ul;

            @each $element in $elements-sm {
                #{$element} + p {
                    margin-top: var(--g-spacing-sm);
                }
            }

            @each $element in $elements-xl {
                @for $i from 1 through 6 {
                    #{$element} + h#{$i} {
                        margin-top: var(--g-spacing-xl);
                    }
                }
            }

            @each $element in $elements-md {
                #{$element} + p {
                    margin-top: var(--g-spacing-md);
                }

                p + #{$element} {
                    margin-top: var(--g-spacing-md);
                }
            }

            blockquote + p {
                margin-top: var(--g-spacing-lg);
            }

            p + blockquote {
                margin-top: var(--g-spacing-lg);
            }

            &--color {
                &,
                li,
                p,
                blockquote {
                    color: var(--markdown-color);
                }
            }

            &--color &--link {
                &:active,
                &:hover {
                    color: var(--markdown-color);
                    filter: var(--c-markdown-link-color-override-active);
                }

                &:focus {
                    color: var(--markdown-color);
                    filter: var(--c-markdown-link-color-override-focus);
                }
            }
        }
    }
}
