@use "../../styles/mixins" as *;
@include layer(ui) {
    .text {
        font-family: var(--g-font-family-primary);
        margin: 0;
        margin-bottom: var(--g-paragraph-spacing-standard);
        letter-spacing: var(--g-letter-spacing-standard);
        color: var(--s-color-text-primary);
    }

    .style {
        //DISPLAY HEADING
        &--display-heading-1 {
            font-weight: var(--g-font-weight-bold);
            line-height: var(--g-line-height-1100);
            font-size: var(--g-font-size-1100);

            @include for-tablet-up {
                line-height: var(--g-line-height-1500);
                font-size: var(--g-font-size-1500);
            }
        }
        &--display-heading-2 {
            font-weight: var(--g-font-weight-bold);
            line-height: var(--g-line-height-1100);
            font-size: var(--g-font-size-1100);

            @include for-tablet-up {
                font-size: var(--g-font-size-1400);
                line-height: var(--g-line-height-1400);
            }
        }
        &--display-heading-3 {
            font-weight: var(--g-font-weight-bold);
            line-height: var(--g-line-height-1000);
            font-size: var(--g-font-size-1000);

            @include for-tablet-up {
                line-height: var(--g-line-height-1200);
                font-size: var(--g-font-size-1200);
            }
        }
        &--display-heading-4 {
            font-weight: var(--g-font-weight-bold);
            line-height: var(--g-line-height-1000);
            font-size: var(--g-font-size-1000);

            @include for-tablet-up {
                font-size: var(--g-font-size-1100);
                line-height: var(--g-line-height-1100);
            }
        }
        //DETAIL
        &--detail-1 {
            font-weight: var(--g-font-weight-medium);
            font-size: var(--g-font-size-100);
            line-height: var(--g-line-height-100);
            letter-spacing: var(--g-letter-spacing-loose);
            text-transform: uppercase;

            @include for-tablet-up {
                font-size: var(--g-font-size-200);
                line-height: var(--g-line-height-200);
            }
        }
        &--detail-2 {
            font-weight: var(--g-font-weight-bold);
            font-size: var(--g-font-size-300);
            line-height: var(--g-line-height-300);
            letter-spacing: var(--g-letter-spacing-xloose);
            line-height: var(--g-line-height-300);
            text-transform: uppercase;
        }

        &--detail-3 {
            font-weight: var(--g-font-weight-bold);
            font-size: var(--g-font-size-400);
            line-height: var(--g-line-height-400);
            letter-spacing: var(--g-letter-spacing-xloose);
            text-transform: uppercase;
        }
        //HEADING
        &--heading-1 {
            font-weight: var(--g-font-weight-bold);
            font-size: var(--g-font-size-1000);
            line-height: var(--g-line-height-1000);

            @include for-tablet-up {
                font-size: var(--g-font-size-1300);
                line-height: var(--g-line-height-1300);
            }
        }
        &--heading-2 {
            font-weight: var(--g-font-weight-bold);
            line-height: var(--g-line-height-800);
            font-size: var(--g-font-size-800);

            @include for-tablet-up {
                font-size: var(--g-font-size-1000);
                line-height: var(--g-line-height-1000);
            }
        }
        &--heading-3 {
            font-weight: var(--g-font-weight-bold);
            line-height: var(--g-line-height-700);
            font-size: var(--g-font-size-700);

            @include for-tablet-up {
                line-height: var(--g-line-height-800);
                font-size: var(--g-font-size-800);
            }
        }
        &--heading-4 {
            font-weight: var(--g-font-weight-bold);
            line-height: var(--g-line-height-600);
            font-size: var(--g-font-size-600);
        }
        &--heading-5 {
            font-weight: var(--g-font-weight-bold);
            line-height: var(--g-line-height-500);
            font-size: var(--g-font-size-500);
        }
        &--heading-6 {
            font-weight: var(--g-font-weight-bold);
            line-height: var(--g-line-height-400);
            font-size: var(--g-font-size-400);
        }
        // BODY
        &--body-1 {
            font-weight: var(--g-font-weight-light);
            font-size: var(--g-font-size-400);
            line-height: var(--g-line-height-400);
        }
        &--body-2 {
            font-weight: var(--g-font-weight-light);
            font-size: var(--g-font-size-300);
            line-height: var(--g-line-height-300);
        }

        &--body-3 {
            font-weight: var(--g-font-weight-light);
            font-size: var(--g-font-size-200);
            line-height: var(--g-line-height-200);
        }
        //DISPLAY BODY
        &--display-body-1 {
            font-weight: var(--g-font-weight-light);
            font-size: var(--g-font-size-500);
            line-height: var(--g-line-height-500);
            @include for-tablet-up {
                font-size: var(--g-font-size-600);
                line-height: var(--g-line-height-600);
            }
        }
        &--display-body-2 {
            font-weight: var(--g-font-weight-light);
            font-size: var(--g-font-size-500);
            line-height: var(--g-line-height-500);
        }
        //DATA
        &--data-1 {
            font-weight: var(--g-font-weight-light);
            font-size: var(--g-font-size-300);
            line-height: var(--g-line-height-300);

            @include for-tablet-up {
                font-weight: var(--g-font-weight-regular);
                font-size: var(--g-font-size-400);
                line-height: var(--g-line-height-400);
            }
        }
        &--data-2 {
            font-weight: var(--g-font-weight-light);
            font-size: var(--g-font-size-300);
            line-height: var(--g-line-height-300);
        }
    }

    .emphasis {
        &--none {
            font-weight: var(--g-font-weight-light);
        }
        &--1 {
            font-weight: var(--g-font-weight-regular);
        }
        &--2 {
            font-weight: var(--g-font-weight-bold);
        }
    }
}
